import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/elements/generic-section/generic-section.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/elements/info-section/info-section.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/elements/quote-section/quote-section.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/features/discover-more-section/discover-more-section.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/app/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["IdeasSection"] */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/features/ideas-section/ideas-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/__w/labs-venture/labs-venture/node_modules/next/dist/client/app-dir/link.js");
